<template>



  <b-card-code
    no-body
    title="Configurations"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >

      <b-alert
          v-if="error"
          variant="danger"
          show
          class="m-2"
        >

          <div class="alert-body">
            <span><strong>Error </strong> {{ error }}</span>

          </div>

        </b-alert>

      <b-table
        striped
        hover
        v-if="configurations"
        :items="configurations"
        :fields="fields"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>

        </template>

        <template #cell(createdAt)="data">
          {{ data.item.createdAt|formatDateTime }}
        </template>

        <template #cell(actions)="data">
          <router-link :to="{ name: 'edit-configuration', params: { configurationId: data.item.id }}">
            <b-button

              variant="primary"
              size="sm"
              class="btn-tour-finish mr-1"
            >
              Edit
            </b-button>

          </router-link>

          <a
            class="text-danger"
            @click="remove(data.item.id)"
          >Remove</a>
        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, VBTooltip, BPagination, BSpinner, BOverlay, BAlert
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BAlert,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      configurations: null,
      fields: ['id', 'configName', 'channel', 'skuGroup', 'sku', 'value', 'createdAt', 'actions'],
      currentPage: 1,
      perPage: 25,
      lastPage: 0,
      error:null,
      rows: 0,
    }
  },

  created() {
    this.loadConfigurations()
  },

  methods: {

    handlePageChange(value) {
      this.page = value

      this.loadConfigurations(this.page)
    },

    loadConfigurations(page = 1) {
      this.loading = true

      this.$store.dispatch('configuration/fetchAll', page)
        .then(result => {
          this.configurations = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
          this.error = error.response?.data?.message || error?.message

        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('configuration/remove', id).then(
            result => {
              console.log(result)
              this.loadConfigurations()
            },
            error => {
              console.log(err)
            },
          ).finally(() => {
            this.loading = false
          })
        }
      },
      error => {
        console.log(err)
      })
    },
    onStatusChange($event, id) {
      const data = {
        id,
        status: event.target.value,
      }

      this.$store.dispatch('configuration/update', data)
        .then(result => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
